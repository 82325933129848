import LocomotiveScroll from 'locomotive-scroll';
import { initImageLoader } from './loader';
import { initSmoothScroll, initFooterLinkFocus } from './scroll';
import { loadVideo } from './loader/video';

const onAbout = () => {
  return window.location.pathname.includes("about")
}

document.addEventListener('astro:page-load', async() => {
  console.log('Designed & Developed by N. Yasmeen');

  const smoothScroll = initSmoothScroll()
  await initImageLoader()

  if (onAbout())
    loadVideo();

  initFooterLinkFocus(smoothScroll)
});
