import imagesLoaded from 'imagesloaded';
export { generateLoaderMessage } from './quotes'

export const showLoader = async(incomingDocument: Document=document) => {
    incomingDocument.documentElement.dataset.loading = 'true';
    incomingDocument.getElementById('loader')?.classList.remove('hidden'); 
}

export const hideLoader = () => {
    document.documentElement.dataset.loading = 'false';
    setTimeout(() => {

        document.getElementById('loader')?.classList.add('hidden');
    }, 800)
}

export const isLoaderShowing = () => {
    return !document.getElementById('loader')?.classList.contains('hidden');
}

const updateProgressDisplay = (value: string) => {
    const progressValueEl = document.querySelector('.loader__progress-text') as HTMLElement

    if (progressValueEl)
        progressValueEl.innerText = value
}

export const isDataLoaded = () => {
    return sessionStorage.getItem('imagesLoaded') === 'true'; 
}

const setDataLoaded = (resolve: (value?: unknown) => void): void => {
    sessionStorage.setItem('imagesLoaded', 'true')

    resolve()
}

const loadImages = async() => {
    return new Promise((resolve, reject) => {
        const mainContainer = document.getElementById('main')

        if (!mainContainer) {
            reject(new Error("Main Content wrapper not found"));
            return;
        }

        const imgLoad = imagesLoaded(mainContainer, { background: true });

        imgLoad.on('progress', (instance, image) => {
            const percentComplete = Math.round((instance.progressedCount / instance.images.length) * 100);
            updateProgressDisplay(percentComplete.toString())
        })

        imgLoad.on('always', () => setDataLoaded(resolve))
        imgLoad.on('fail', reject)
    })
}

const onHome = () => {
    return window.location.pathname === '/' || window.location.pathname.includes("index")
}

export async function initImageLoader() {
    showLoader();

    if (!isDataLoaded()) {
        try {
            await loadImages();

        } catch(error) {
            console.error('Image loading failed: ', error)
        } finally {
            hideLoader();

        }
    } else {
         updateProgressDisplay("100")
        if (isLoaderShowing())
            hideLoader()
    }
}






