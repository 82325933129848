export const initFooterLinkFocus = (scroll: LocomotiveScroll) => {
    const footerLinks = document.querySelectorAll('footer a')
  
    footerLinks.forEach(link => {
      link.addEventListener('focus', (event) => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      } )
    })
}