
export const loadVideo = () => {
    const video = document.getElementById('visualizer') as HTMLVideoElement;

    if ('IntersectionObserver' in window) {
        let observer = new IntersectionObserver(function(entries, observer) {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              video.load();
              observer.disconnect(); // Stop observing after video loads
            }
          });
        });
        observer.observe(video);
      } else {
        // Fallback for browsers without IntersectionObserver support
        video.load();
      }

}




//   document.addEventListener("DOMContentLoaded", function() {
//     const lazyVideos = document.querySelectorAll('.lazy-video');
    
//     const lazyVideoObserver = new IntersectionObserver((entries, observer) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           const video = entry.target;
//           const source = video.querySelector('source');
//           source.src = video.dataset.src;
//           video.load();
//           observer.unobserve(video);
//         }
//       });
//     });

//     lazyVideos.forEach((video) => {
//       lazyVideoObserver.observe(video);
//     });
//   });